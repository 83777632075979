import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Menu from "../components/menu/menu"
import Address from "../components/contatos/address"
import Intro from "../components/contatos/intro"
import IntroMobile from "../components/contatos/introMobile"
import ContactInfo from "../components/contatos/contactInfo"
import Mapa from "../components/contatos/mapa"
import Footer from "../components/footer/footer"

import { Link } from "gatsby"
import font from "../fonts/fonts.module.scss"
import styled from "styled-components"
import logoLink from "../images/global/logo-seta-hollow.svg"

const QuemSomosPage = ({data}) => {

  function NotFound() {
    if (typeof window !== 'undefined') {
      window.location = '/';
    }
    return null;
  }
  
  return(
    <Layout inv={data.globalJson.invisual}>
      <SEO title="Contatos"/>
      {useBreakpoint().mobile ? (
        <>
          <Menu data={data.globalJson.menu}/>
          <IntroMobile data={data.quemSomosJson.intro}/>
          <StyledContent>
            <p className={font.aGM + " subtitle"}>{data.quemSomosJson.subtitle}</p>
            <p className={font.mER  + " title"}>{data.quemSomosJson.title}</p>
            <p className={font.aGR + " text"}>{data.quemSomosJson.text}</p>
            <Link to={data.quemSomosJson.link} className={font.aGM + " mais-link"}>
              <img src={logoLink} alt="seta"/>
              {data.quemSomosJson.linkTxt}
            </Link>
          </StyledContent>
          <Footer data={data.globalJson.footer} small/>
        </>
      ) : (
        NotFound()
      )}
    </Layout>
  )
}
export default QuemSomosPage

const StyledContent = styled.div`
  position: relative;
  width: 90%;
  padding: 30px;
  .about-back{
    position: absolute;
    top: -3em;
    left: 0;
    color: #ffffff;
    letter-spacing: 0.12em;
    font-size: calc(13px + 15 * (100vw - 320px) / 448);
  }
  .subtitle{
    color: #fa4616;
    margin-bottom: 0.5em;
    font-size: calc(13px + 15 * (100vw - 320px) / 448);
  }
  .title{
    font-size: calc(13px + 15 * (100vw - 320px) / 448);
  }
  .text{
    margin: 1.5em 0;
    font-size: calc(13px + 15 * (100vw - 320px) / 448);
  }
  .mais-link{
    display: flex;
    font-size: calc(13px + 15 * (100vw - 320px) / 448);
    img{height: 1em; margin-right: 1em;}
  }
`

export const Json = graphql`
  query quemsomos {
    quemSomosJson{
      intro{
        title
        mapa{
          childImageSharp{
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title
      subtitle
      text
      link
      linkTxt
    }
    globalJson{
      invisual{
        link
        linkTxt
      }
      menu{
        leftPage{
          background{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content{
            subtitle
            title
            text
            link
            linkTxt
          }
          info{
            address
            contact{
              newsletter
              email
              phone
            }
          }
          livro{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 3840
              )
            }
          }
        }
        rightPage{
          bg{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          links{
            link
            linkTxt
          }
          highlights{
            subtitle
            title
            list{
              img{
                childImageSharp{
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 3840
                  )
                }
              }
              title
              subtitle
              link
            }
          }
        }
      }
      footer{
        livro{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              width: 3840
            )
          }
        }
        bg{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contacts{
          email
          phone1
        }
        address
        privacyInfo{
          linkTxt
          link
        }
      }
    }
  }
`